<template>
    <confirmation-dialog v-model="showDialog" :title="title" :confirm="t('CHALLENGE_DIALOG_SUBMISSION_RESULT_CONFIRM')" @confirm="confirm" :cancel="false" :loading="isLoading" class="submissionResultDialog">
        <p v-html="message" />
    </confirmation-dialog>
</template>

<script setup lang="ts">
import { computed, onMounted, ref, watch } from 'vue';
import { IChallenge, ISubmissionResult } from '@cyber-range/cyber-range-api-ctf-challenge-client';
import { useApiClientStore } from '@stores/apiClientStore';
import { encode } from 'html-entities';
import { useChallengeStore } from '@/stores/challengeStore';
import { useSubmissionStore } from '@/stores/submissionStore';
import { useI18n } from 'vue-i18n-composable';

const props = defineProps<{
    challenge: IChallenge;
    submissionResult: ISubmissionResult;
    value: boolean;
}>();

const emit = defineEmits<{
    (name: 'confirm', state: boolean): void;
    (name: 'input', state: boolean): void;
}>();

const showDialog = ref(false);

const { t } = useI18n();

const title = computed(() => 
{
    return props.submissionResult.correct ? t('CHALLENGE_DIALOG_SUBMISSION_RESULT_CORRECT_TITLE') : t('CHALLENGE_DIALOG_SUBMISSION_RESULT_INCORRECT_TITLE');
});

const unlocks = computed(() => 
{
    const challenges: string[] = [];
    for (const id of props.challenge.unlocks || []) 
    {
        const challenge = useChallengeStore().getChallenge(id);
        if (!challenge?.name) continue;

        const isAlreadyUnlocked = !useSubmissionStore().isLocked(id, props.challenge.id);
        if (!isAlreadyUnlocked) 
        {
            challenges.push(challenge.name);
        }
    }
    return challenges;
});

const message = computed(() => 
{
    let message = "";
    if (props.submissionResult.correct && unlocks.value.length) 
    {
        message = t('CHALLENGE_DIALOG_SUBMISSION_RESULT_CORRECT_WITH_UNLOCKS_MESSAGE', {
            points: props.submissionResult.points,
            challenges: new Intl.ListFormat(undefined, { type: 'conjunction' }).format(unlocks.value.map(name => `<b>${encode(name)}</b>`))
        }).toString();
    } 
    else if (props.submissionResult.correct) 
    {
        message = t('CHALLENGE_DIALOG_SUBMISSION_RESULT_CORRECT_MESSAGE', { points: props.submissionResult.points }).toString();
    } 
    else 
    {
        message = t('CHALLENGE_DIALOG_SUBMISSION_RESULT_INCORRECT_MESSAGE').toString();
    }
    return message;
});

const isLoading = computed(() => useApiClientStore().isLoading);

watch(() => props.value, async (value) => 
{
    if (value) showDialog.value = value;
});

onMounted(async () => 
{
    showDialog.value = props.value;
});

const confirm = async () => 
{
    emit('confirm', true);
    close();
};

const close = () => 
{
    showDialog.value = false;
    emit('input', false);
};
</script>