<template>
    <div>

        <v-row class="mt-0">
            <v-col cols="auto">
                <h1>{{t('CHALLENGES_TITLE')}}</h1>
            </v-col>
            <v-spacer/>
            <v-col cols="1">
                <add-challenge-menu v-if="canCreateChallenge(competition.id, competition.organizationId)" @added='onChallengesAdded' />
            </v-col>
            <v-col cols="12" class="ma-0 pa-0 loadingRow">
                <loading v-if="isLoading"/>
            </v-col>

            <v-col v-if="isChallengesFetched && challengeCategories.length === 0" cols="12" align="center" class="mt-10 pt-10">
                <template v-if="canUpdateCompetition(competition.id, competition.organizationId)">
                    <p id="noChallengesAdmin">{{t('NO_CHALLENGES_ADMINS')}}</p>
                </template>
                <template v-else-if="competitionStartTime && !competitionHasStarted">
                    <p class="mb-4">{{t('CHALLENGES_COUNTDOWN')}}</p>
                    <countdown-clock id="challengesCountdown" :end="competitionStartTime" @timed="onCompetitionStarted"/>
                </template>
                <template v-else>
                    <p id="noChallengesPlayer">
                        <template v-if="refetchCompetitionAfterStarted">
                            {{t('NO_CHALLENGES_PLAYERS')}}
                        </template>
                        <template v-else>
                            {{t('FETCHING_CHALLENGES')}}
                        </template>
                    </p>
                    <v-btn icon text @click="onRefreshClicked" :disabled="isLoading">
                        <v-icon>refresh</v-icon>
                    </v-btn>
                </template>
            </v-col>

            <v-col v-if="canUpdateCompetition(competition.id, competition.organizationId) || !competitionHasEnded" cols="12">
                <v-toolbar :dark='isDark' class="elevation-0" id="challengeCategories">
                    <v-toolbar-items class="pr-5">
                        <draggable class="draggable" :disabled="!canUpdateCompetition(competition.id, competition.organizationId)" :list="challengeCategories" @start="onDragging" @end="onDragged">
                            <v-btn text v-for="category in challengeCategories" :key="category" :dark='isDark' :id="`challengeCategory_${category}`"
                                :to="{name:categorizedChallengeRouteName,params:{category:category}}" class="categoryButton">
                                <a :href='category'>
                                    {{category}}
                                </a>
                            </v-btn>
                        </draggable>
                    </v-toolbar-items>
                </v-toolbar>
            </v-col>

        </v-row>


        <v-col v-if="!canUpdateCompetition(competition.id, competition.organizationId) && competitionHasEnded" cols="12" align="center" class="mt-10 pt-10 mb-10 pb-10">
            <p id="competitionHasEnded" class="mb-10 mt-10 pb-10 pt-10">{{t('COMPETITION_HAS_ENDED')}}</p>
        </v-col>
        <router-view v-else class="view"></router-view>


        <v-row v-if="(competitionStartTime && competitionHasStarted) || !competitionStartTime">
            <v-col v-if="isScoreboardEnabled" cols="12" md="4" lg="3">
                <score-window />
            </v-col>
            <v-col cols="12" :md="isScoreboardEnabled ? (competitionEndTime ? 4 : 8) : 8"  :lg="isScoreboardEnabled ? (competitionEndTime ? 6 : 9) : 9">
                <activity-window />
            </v-col>
            <v-col cols="12" md="4"  lg="3" v-if="competitionEndTime">
                <countdown-window id="competitionEndingCountdown" :end="competitionEndTime" @timed="onCompetitionEnded" />
            </v-col>
        </v-row>
    </div>
</template>

<script setup lang="ts">
import { ref, computed, onMounted, onBeforeUnmount } from 'vue';
import { Subject, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import moment from 'moment';
import draggable from 'vuedraggable';
import { IChallenge } from '@cyber-range/cyber-range-api-ctf-challenge-client';
import Route from '@/interfaces/route';
import { ICompetition, Competition } from '@cyber-range/cyber-range-api-ctf-competition-client';
import TitleStrings from '@entities/strings/definitions/titleStrings';
import { useThemeStore } from '@/stores/themeStore';
import { useApiClientStore } from '@stores/apiClientStore';
import { useCompetitionStore } from '@stores/competitionStore';
import { useChallengeStore } from '@stores/challengeStore';
import { useAuthorizationStore } from '@stores/authorizationStore';
import { useSubmissionStore } from '@/stores/submissionStore';
import { usePageTitle } from '@/composables/usePageTitle';
import { useRoute, useRouter } from 'vue-router/composables';
import { useI18n } from 'vue-i18n-composable';

usePageTitle(TitleStrings.en.TITLE_CHALLENGES);

const CATEGORIES_UPDATE_DELAYED_IN_MS = 2000;
const FETCH_CHALLENGE_DELAYED_IN_MS = 1000;

const route = useRoute();
const router = useRouter();

const {t} = useI18n();

const themeStore = useThemeStore();
const apiClientStore = useApiClientStore();
const competitionStore = useCompetitionStore();
const challengeStore = useChallengeStore();
const authorizationStore = useAuthorizationStore();
const submissionStore = useSubmissionStore();

const challengeCategories = computed(() => challengeStore.getChallengeCategories);
const isChallengesFetched = computed(() => challengeStore.isChallengesFetched);
const isDark = computed(() => themeStore.isDark);
const isLoading = computed(() => apiClientStore.isLoading);
const competitionApiClient = computed(() => apiClientStore.competitionApiClient);
const competition = computed(() => competitionStore.currentCompetition);
const categorizedChallengeRouteName = computed(() => Route.CategorizedChallenges.name);
const isScoreboardEnabled = computed(() => !competition.value?.settings?.hideDashboard);

const canUpdateCompetition = (competitionId: string, organizationId: string): boolean => {
    return authorizationStore.canUpdateCompetition(competitionId, organizationId);
};

const canCreateChallenge = (competitionId: string, organizationId: string): boolean => {
    return authorizationStore.canCreateChallenge(competitionId, organizationId);
};

const dragSubject = new Subject<void>();
let dragSubscription: Subscription;

const competitionStartTime = ref('');
const competitionEndTime = ref('');
const competitionHasStarted = ref(false);
const competitionHasEnded = ref(false);
const refetchCompetitionAfterStarted = ref(false);

const renderChallenges = () => {
    competitionStartTime.value = competition.value?.settings?.startTime || '';
    competitionHasStarted.value = moment().isAfter(competitionStartTime.value);
    refetchCompetitionAfterStarted.value = (competitionStartTime.value && competitionHasStarted.value) || !competitionStartTime.value;

    competitionEndTime.value = competition.value?.settings?.endTime || '';

    if (!route.params?.category && challengeCategories.value.length > 0) {
        router.replace({ ...Route.CategorizedChallenges, params: { category: challengeCategories.value[0] } });
    }
};

const onDragging = () => {
    dragSubject.next();
};

const onDragged = () => {
    dragSubject.next();
};

const onChallengesAdded = async (challenges: IChallenge[]) => {
    await challengeStore.fetchChallenges();
    router.push({ name: Route.CategorizedChallenges.name, params: { category: challenges[0].category.toLowerCase() } });
};

const onCompetitionEnded = () => {
    competitionHasEnded.value = true;
};

const onRefreshClicked = async () => {
    await challengeStore.fetchChallenges();
    renderChallenges();
};

const onCompetitionStarted = async () => {
    competitionHasStarted.value = true;

    // Delay challenges fetching to work around any time sync between UI and APIs.
    await new Promise((resolve) => setTimeout(resolve, FETCH_CHALLENGE_DELAYED_IN_MS));

    await challengeStore.fetchChallenges();
    renderChallenges();

    refetchCompetitionAfterStarted.value = true;
};

const updateCategories = async () => {
    const categories = challengeCategories.value.map((name: string, index: number) => ({ name, index }));
    await competitionApiClient.value.update(competition.value.id, new Competition(<ICompetition>{ categories }));
    await competitionStore.refreshCurrentCompetition();
};

onMounted(async () => {
    if (!submissionStore.isSubmissionFetched) {
        submissionStore.fetchSubmissions({ background: true });
        submissionStore.fetchMyTeamsChallengesStatistics({ background: true });
    }

    if (!isChallengesFetched.value) {
        await challengeStore.fetchChallenges();
    }

    dragSubscription = dragSubject.pipe(debounceTime(CATEGORIES_UPDATE_DELAYED_IN_MS)).subscribe({ next: updateCategories });

    renderChallenges();
});

onBeforeUnmount(() => {
    dragSubscription.unsubscribe();
});
</script>

<style scoped>
@import "./challenges.css";

/* Cyber Icons Theme (.cybericons) */
.cybericons .categoryButton, .cybericons #competitionEndingCountdown {
    font-family: ShareTechMono !important
}
</style>
<style>
#challengeCategories .v-toolbar__content{
    height: auto !important;
}
</style>