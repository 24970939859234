<template>
    <v-row wrap v-if="statistics" class="ml-4 caption solvedBy">
        <v-col cols="12" v-if="statistics.successfulAttempts === 0">
            {{ t('CHALLENGE_DIALOG_SOLVED_BY_NONE') }}
        </v-col>
        <v-col cols="12" v-if="statistics.successfulAttempts === 1">
            {{ t('CHALLENGE_DIALOG_SOLVED_BY_SINGULAR') }}
        </v-col>
        <v-col cols="12" v-if="statistics.successfulAttempts > 1">
            {{ t('CHALLENGE_DIALOG_SOLVED_BY_PLURAL', {number: statistics.successfulAttempts}) }}
        </v-col>
    </v-row>
</template>

<script setup lang="ts">
import { defineProps } from 'vue';
import { IChallengeStatistics } from '@cyber-range/cyber-range-api-ctf-challenge-client';
import { useI18n } from 'vue-i18n-composable';

const props = defineProps<{ statistics: IChallengeStatistics; }>();
const { t } = useI18n();
</script>