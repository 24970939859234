<template>
    <confirmation-dialog v-model="showDialog" :title="dialogTitle" @confirm="confirm" @cancel="cancel" :loading="isLoading" :confirm="dialogConfirmText" :cancel="dialogCancelText" class="hintDialog">
        <loading v-if="showLoading" />
        <span v-else id="hintDialogMessage">
                {{hint.message || t('CHALLENGES_HINT_DIALOG_MESSAGE', {cost: hint.cost})}}
        </span>
    </confirmation-dialog>
</template>

<script setup lang="ts">
import { computed, onMounted, ref, watch } from 'vue';
import { IHint} from '@cyber-range/cyber-range-api-ctf-challenge-client';
import { useApiClientStore } from '@stores/apiClientStore';
import { useI18n } from 'vue-i18n-composable';

const props = defineProps<{
    challengeId: string;
    hint: IHint;
    points?: number;
    value: boolean;
}>();

const emit = defineEmits<{
    (name: 'confirm', state: boolean): void;
    (name: 'cancel', state: boolean): void;
    (name: 'input', state: boolean): void;
}>();

const showLoading = ref(false);
const showDialog = ref(false);

const { t } = useI18n();

const isLoading = computed(() => useApiClientStore().isLoading);
const challengeApiClient = computed(() => useApiClientStore().challengeApiClient);
const backgroundChallengeApiClient = computed(() => useApiClientStore().backgroundChallengeApiClient);

const dialogTitle = computed(() => 
{
    if (showLoading.value) return ' ';

    return props.hint.message ? t('CHALLENGES_HINT_DIALOG_UNLOCKED_TITLE') : t('CHALLENGES_HINT_DIALOG_QUESTION_TITLE');
});

const dialogConfirmText = computed(() => 
{
    if (showLoading.value) return false;

    return props.hint.message ? t('CHALLENGES_HINT_DIALOG_UNLOCKED_CONFIRM') : t('CHALLENGES_HINT_DIALOG_CONFIRM');
});

const dialogCancelText = computed(() => 
{
    if (showLoading.value) return false;

    return props.hint.message ? false : t('CHALLENGES_HINT_DIALOG_CANCEL');
});

watch(() => props.value, async (value) => 
{
    if (value) await load();
});

onMounted(async () => 
{
    if (props.value) await load();
});

const load = async () => 
{
    showDialog.value = true;

    if (!props.hint.message) 
    {
        showLoading.value = true;
        try 
        {
            props.hint.message = (await backgroundChallengeApiClient.value.getChallengeHint(props.challengeId, props.hint.id)).message;
        } 
        finally 
        {
            showLoading.value = false;
        }
    }
};

const confirm = async () => 
{
    if (props.hint.message) 
    {
        emit('confirm', true);
        close();
    } 
    else 
    {
        props.hint.message = (await challengeApiClient.value.putChallengeHint(props.challengeId, props.hint.id))?.message;
    }
};

const cancel = async () => 
{
    emit('cancel', true);
    close();
};

const close = () => 
{
    showDialog.value = false;
    emit('input', false);
};
</script>