<template>
    <div class="addChallengeMenu">
        <v-menu offset-y :dark="isDark" :light="isLight">
            <template v-slot:activator="{ on: menu, value }">
                <v-tooltip bottom>
                    <template v-slot:activator="{ on: tooltip }">
                        <v-btn outlined small fab v-on="{ ...tooltip, ...menu }" class="addChallengeButton" aria-owns="addChallengeMenuList" :aria-label="t('CHALLENGE_ADD_MENU_LABEL')" aria-haspopup="true" :aria-expanded="`${value}`">
                            <v-icon>add</v-icon>
                        </v-btn>
                    </template>
                    <span>{{t('CHALLENGE_ADD_TOOLTIP')}}</span>
                </v-tooltip>
            </template>
            <v-list :dark="isDark" :light="isLight" id="addChallengeMenuList">
                <v-list-item>
                    <v-list-item-title>
                        <v-btn text @click="onAddChallengeFromLibraryClicked" class="addChallengeFromLibraryButton">{{t('CHALLENGE_ADD_FROM_LIBRARY')}}</v-btn>
                    </v-list-item-title>
                </v-list-item>
                <v-list-item>
                    <v-list-item-title>
                        <v-btn text @click="onAddChallengeFromScratchClicked" class="addChallengeFromScrathButton">{{t('CHALLENGE_ADD_FROM_SCRATCH')}}</v-btn>
                    </v-list-item-title>
                </v-list-item>
            </v-list>
        </v-menu>
        <add-challenges-from-library-dialog v-if="entries.length > 1" v-model="showAddChallengeDialog" @confirm="onChallengesAdded" @cancel="onCancelChallengesAddded" :libraryEntries="entries" />
        <add-edit-challenge-dialog v-else-if="entries.length === 1" v-model="showAddChallengeDialog" @confirm="onChallengesAdded([$event])" @cancel="onCancel" id="addEditChallengeFromScratchDialog" :libraryEntryId="entries[0].id" />
        <library-entries-dialog v-model="showAddChallengeFromLibraryDialog" :entries.sync="entries" @confirm="onLibraryEntriesSelected" @cancel="onCancel" id="addEditChallengeFromLibraryDialog" />
    </div>
</template>

<script setup lang="ts">
import { ref, computed } from 'vue';
import { defineEmits } from 'vue';
import { IChallenge } from '@cyber-range/cyber-range-api-ctf-challenge-client';
import { ILibraryEntry, LibraryEntry } from '@cyber-range/cyber-range-api-ctf-library-client';
import { useThemeStore } from '@/stores/themeStore';
import { useI18n } from 'vue-i18n-composable';

const emit = defineEmits<{ (name: 'added', challenges: IChallenge[]): void }>();

const { t } = useI18n();

const themeStore = useThemeStore();
const isDark = computed(() => themeStore.isDark);
const isLight = computed(() => themeStore.isLight);

const entries = ref<ILibraryEntry[]>([]);
const showAddChallengeDialog = ref(false);
const showAddChallengeFromLibraryDialog = ref(false);

const onAddChallengeFromScratchClicked = () => 
{
    entries.value = [new LibraryEntry()];
    showAddChallengeDialog.value = true;
};

const onAddChallengeFromLibraryClicked = () => 
{
    showAddChallengeFromLibraryDialog.value = true;
};

const onChallengesAdded = (challenges: IChallenge[]) => 
{
    emit('added', challenges);
    entries.value = [];
};

const onCancelChallengesAddded = () => 
{
    showAddChallengeDialog.value = false;
    showAddChallengeFromLibraryDialog.value = true;
};

const onCancel = () => 
{
    entries.value = [];
};

const onLibraryEntriesSelected = () => 
{
    showAddChallengeDialog.value = true;
};
</script>
