<template>
    <v-container align="top" fluid class="ml-5 mr-5">
        <v-row row wrap justify="center" align="center">
            <v-col cols="12" align="center" class="mt-10 pt-10">
                <v-container>
                    <v-row row wrap justify="center">
                        <v-col cols="4" align="center">
                            <playing-options id="playerOptions" />
                        </v-col>
                    </v-row>
                </v-container>
            </v-col>
        </v-row>
    </v-container>
</template>

<script setup lang="ts">
import { usePageTitle } from '@/composables/usePageTitle';
import TitleStrings from '@/entities/strings/definitions/titleStrings';

usePageTitle(TitleStrings.en.TITLE_WELCOME);
</script>