<template>
    <v-text-field
        v-model="flag"
        :label="t('CHALLENGE_DIALOG_FLAG')"
        :rules="flagRules"
        :counter="maxFlagLength"
        :disabled="disabled"/>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import { defineProps, defineEmits } from 'vue';
import Rule from '@/validations/Rule';
import { useI18n } from 'vue-i18n-composable';

const props = defineProps<{ 
    disabled?: boolean;
    value: string;
}>();

const emit = defineEmits<{ (name: 'input', value: string): void }>();

const { t } = useI18n();

const maxFlagLength = 256;
const flagRules = [Rule.require, (v: string) => Rule.maxLength(v, maxFlagLength)];

const flag = computed<string>({
    get() 
    {
        return props.value;
    },
    set(value: string) 
    {
        emit('input', value);
    }
});
</script>
