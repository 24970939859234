<template>
    <v-dialog v-model="selectedValue" max-width="50%" persistent :dark='isDark' :light="isLight">
        <v-card class="confirmationDialog" :dark='isDark' role="dialog" :aria-labelledby="dialogTitleId" aria-modal="true">
            <v-card-title class="headline">
                <span v-if="$slots.titleIcon" class="titleIcon">
                    <slot name="titleIcon"></slot>
                </span>
                <span :id="dialogTitleId">
                    {{ title || t('TITLE')}}
                </span>
                <v-spacer/>
                <slot name="titleActions"></slot>
            </v-card-title>
            <v-card-text :class="dense ? 'pa-0' : 'body'">
                <slot></slot>
            </v-card-text>
            <v-card-actions class="pl-6 pr-6 pb-4 pt-4">
                <slot name="bottomLeft"></slot>
                <v-spacer></v-spacer>
                <slot name="bottom"></slot>
                <v-btn class="cancelButton"
                    v-if="cancel!==false"
                    text
                    :dark="isDark"
                    :color="cancelColor || 'cancel'"
                    @click.native="show = false"
                    @click="onCancelClicked"
                    :disabled="disabled || loading || cancelDisabled"
                >{{ cancel || t('CANCEL') }}</v-btn>
                <v-btn class="confirmButton elevation-0"
                    v-if="confirm!==false"
                    :color="confirmColor || 'confirm'"
                    :dark="!isConfirmLight" :light="isConfirmLight"
                    @click.native="show = false"
                    @click="onConfirmClicked"
                    :disabled="disabled || loading || confirmDisabled"
                >{{ confirm || t('CONFIRM') }}</v-btn>
                <slot name="bottomRight"></slot>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script lang="ts">
// Define component name
// defineOptions is not supported until Vue 3.3+
export default defineComponent({
  name: 'ConfirmationDialog',
})
</script>

<script setup lang="ts">
import { ref, watch, computed, onMounted, defineComponent } from 'vue';
import { defineProps, defineEmits } from 'vue';
import { v4 as uuid } from 'uuid';
import { useThemeStore } from "@/stores/themeStore";
import { useI18n } from 'vue-i18n-composable';

const props = defineProps<{
    cancel?: any;
    confirm?: any;
    title?: string;
    disabled?: boolean;
    value?: boolean;
    loading?: boolean;
    cancelDisabled?: boolean;
    confirmDisabled?: boolean;
    confirmColor?: string;
    cancelColor?: string;
    dense?: boolean;
}>();

const emit = defineEmits<{
    (name: 'cancel', state: boolean): void;
    (name: 'input', state: boolean): void;
    (name: 'confirm', state: boolean): void;
}>();

const { t } = useI18n();

const isOpen = ref(true);
const selectedValue = ref(false);
const dialogTitleId = uuid();

const themeStore = useThemeStore();

const isDark = computed(() => themeStore.isDialogDark);
const isLight = computed(() => themeStore.isDialogLight);
const isConfirmLight = computed(() => themeStore.isConfirmLight);

watch(() => props.value, (newValue) => {
    selectedValue.value = newValue;
});

const onCancelClicked = () => {
    if (props.cancel !== false && !props.disabled && !props.loading && !props.cancelDisabled) {
        emit('cancel', true);
        emit('input', false);
    }
};

const onConfirmClicked = () => {
    if (props.confirm !== false && !props.disabled && !props.loading && !props.confirmDisabled) {
        emit('confirm', true);
    }
};

onMounted(() => {
    selectedValue.value = props.value;
});
</script>

<style scoped>
/* Shared Scoped Styles */
.theme--dark.v-btn.v-btn--disabled:not(.v-btn--flat):not(.v-btn--text):not(.v-btn--outlined)
{
    background-color: rgba(0, 0, 0, 0.5)  !important;
    color: rgba(256, 256, 256, 0.3) !important;
}
.theme--dark.v-btn.v-btn--disabled
{
    color: rgba(0, 0, 0, 0.6)  !important;
}

/* Default Theme (.default) */
.default .confirmationDialog{
    border: 1px solid var(--v-text-base);
    background-color: white;
    color: rgba(0, 0, 0, 0.87);
}
.default .confirmationDialog .headline{
    background-color: var(--v-application-base);
    color:white;
}
.default .confirmationDialog .body{
    margin-top: 16px;
}

/* Simple Theme (.simple) */
.simple .confirmationDialog
{
    background-color: var(--v-application-base);
}

/* Halloween Theme (.halloween) */
.halloween .confirmationDialog{
    border: 1px solid var(--v-text-base);
    background-color: white;
    color: rgba(0, 0, 0, 0.87);
}
.halloween .confirmationDialog .headline{
    background-color: var(--v-application-base);
    color:white;
}
.halloween .confirmationDialog .body{
    margin-top: 16px;
}

/* ComicBook Theme (.comicbook) */
.comicbook .confirmationDialog{
    font-family: 'ComicNeue-Bold';
    letter-spacing: normal;
}

.comicbook .confirmButton:not(:disabled){
    color: black;
    outline: 1px solid black;
}
</style>

<style>
.titleIcon {
    display: inline-block;
    padding-right: 10px;
}
.confirmationDialog .body{
    padding-left: 2px;
    padding-bottom: 0px !important;
    overflow-y: auto;
    max-height: 60%;
    min-height: 40px;
}

.theme--dark .confirmationDialog .v-input,
.theme--dark .confirmationDialog .v-input input, 
.theme--dark .confirmationDialog .v-input textarea,
.confirmationDialog input,
.confirmationDialog .v-label:not(.error--text)
{
    color: rgba(0, 0, 0, 0.7) !important;
    caret-color: rgba(0, 0, 0, 0.7) !important;
}
.confirmationDialog .v-input__control .v-label,
.confirmationDialog .v-text-field > .v-input__control > .v-input__slot:before 
{
    border-color: rgba(0, 0, 0, 0.4) !important;
}
.theme--dark .confirmationDialog a
{
    color: var(--v-application-base);
}
</style>