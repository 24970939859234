<template>
    <div>
        <h1>{{t('COMPETITIONS')}}</h1>
   
        <v-row class="mt-1">
            <v-col cols="12" class="loadingRow">
                <loading  v-show="isLoading"/>
            </v-col>
            <v-col cols="12" md="6" lg="4" v-for="competition in competitions"  :key="competition.id">
                <competition :competition="competition" />
            </v-col>
        </v-row>

    </div>
</template>

<script setup lang="ts">
import { computed, onMounted, ref } from 'vue';
import { ICompetitionApiClient, ICompetitionPage, ICompetition, CompetitionFilter } from '@cyber-range/cyber-range-api-ctf-competition-client';
import Config from '@/config';
import TitleStrings from '@/entities/strings/definitions/titleStrings';
import { useApiClientStore } from '@stores/apiClientStore';
import { usePageTitle } from '@/composables/usePageTitle';
import { useI18n } from 'vue-i18n-composable';


const competitions = ref<ICompetition[]>([]);
const isLoading = ref<boolean>(true);

const { t } = useI18n();

const client = computed<ICompetitionApiClient>(() => useApiClientStore().competitionApiClient);

usePageTitle(TitleStrings.en.TITLE_COMPETITIONS);

const fetchCompetitions = async (): Promise<void> => 
{
    try 
    {
        isLoading.value = true;

        let page: ICompetitionPage;
        let token: string;

        do 
        {
            page = await client.value.get(new CompetitionFilter(<any>{ token, limit: Config.DEFAULT_FETCH_SIZE }));

            if (page.items.length > 0) 
            {
                for (let item of page.items) competitions.value.push(item);
            }
        } 
        while (token = page.nextPageToken);
    } 
    finally 
    {
        isLoading.value = false;
    }
};

onMounted(async () => 
{
    await fetchCompetitions();
});
</script>

<style scoped>
.loadingRow{
    min-height: 48px;
}
</style>