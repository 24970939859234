<template>
    <v-radio-group v-model="flag" :rules="rules">
        <v-radio
            v-for="choice in challenge.choice.values"
            :key="choice.id"
            :label="choice.text"
            :value="choice.id"
            color="blue"
        />
    </v-radio-group>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import { IChallenge } from '@cyber-range/cyber-range-api-ctf-challenge-client';
import Rule from '@/validations/Rule';

const props = defineProps<{
    value: string; 
    challenge: IChallenge; 
    disabled?: boolean; 
}>();

const emit = defineEmits<{ (name: 'input', flag: string): void }>();

const rules = [Rule.require];

const flag = computed<string>({
    get() 
    {
        return props.value;
    },
    set(flag: string) 
    {
        emit('input', flag);
    }
})
</script>
