<template>
    <confirmation-dialog v-model="showDialog" :title="t('CHALLENGE_TEAM_REQUIRED_DIALOG_TITLE')" :confirm="t('CHALLENGE_TEAM_REQUIRED_DIALOG_CONFIRM')" @confirm="confirm" :cancel="false" :loading="isLoading" class="teamRequiredDialog">
        {{t('CHALLENGE_TEAM_REQUIRED_DIALOG_MESSAGE', {role: roles})}}
    </confirmation-dialog>
</template>

<script setup lang="ts">
import { computed, onMounted, ref, watch } from 'vue';
import { IClaim } from '@cyber-range/cyber-range-api-entitlement-client';
import * as Claims from '@cyber-range/cyber-range-shared-claims';
import { toDisplayRoles } from '@/entities/filters/definitions/toDisplayRoles';
import { useApiClientStore } from '@stores/apiClientStore';
import { useEntitlementStore } from '@stores/entitlementStore';
import { useCompetitionStore } from '@stores/competitionStore';
import { useI18n } from 'vue-i18n-composable';

const props = defineProps<{
    value: boolean;
}>();

const emit = defineEmits<{
    (name: 'confirm',state: boolean): void;
    (name: 'input', state: boolean): void;
}>();

const showDialog = ref(false);

const { t } = useI18n();

const isLoading = computed(() => useApiClientStore().isLoading);
const competition = computed(() => useCompetitionStore().currentCompetition);

const roles = computed(() => 
{
    let roleClaims: IClaim[] = useEntitlementStore().getClaims("role", undefined, [
        competition.value.id,
        competition.value.organizationId,
        Claims.Scope.UNDEFINED,
        Claims.Scope.ANY
    ]);

    return toDisplayRoles(roleClaims
        .filter(
            claim =>
                claim.value !== "user" &&
                (claim.scopes.includes(competition.value.id) ||
                    claim.scopes.includes(competition.value.organizationId) ||
                    claim.scopes.includes(Claims.Scope.ANY))
        )
        .map(claim => claim.value));
});

watch(() => props.value, async (value) => 
{
    if (value) await load();
});

onMounted(async () => 
{
    showDialog.value = props.value;

    if (showDialog.value) await load();
});

const load = async () => 
{
    showDialog.value = true;
};

const confirm = () => 
{
    emit('confirm', true);
    close();
};

const close = () => 
{
    showDialog.value = false;
    emit('input', false);
};
</script>