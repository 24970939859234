<template>
    <v-card role="button" :outlined="!isDark" :aria-label="challenge.name" tag="article" class="elevation-0 alphaBackground obviousWhenSelected" @click="onClicked" v-on:keyup.enter="onClicked" v-on:keyup.space="onClicked" :disabled="isLoading" :color="color" align="center" :class="classes">
        <v-card-title>
            <header>
                {{challenge.name}}
            </header>
        </v-card-title>
        <v-card-text class="text-center">
            <template v-if="isSolved">
                <v-icon>done</v-icon>
            </template>
            <v-icon v-else-if="isLocked">lock</v-icon>
            <v-icon v-else-if="!hasRemainingAttempts">close</v-icon>
            <template v-if="!challenge.enabled">
                <v-icon color='disabled' class='challengeDisabledIcon'>visibility_off</v-icon>
            </template>
            <template v-else-if="!isSolved && !isLocked">
                {{challenge.points}}
            </template>
        </v-card-text>
        <template v-if="showChallenge">
            <locked-challenge-dialog v-if="isLocked && !canUpdateChallenge" v-model="showChallenge" :challengeId="challenge.id" data-testing="locked-challenge-dialog"/>
            <challenge-dialog v-else v-model="showChallenge" :challengeId="challenge.id" @edit="onEditChallenge" @result="onSubmissionResult" class="challengeDialog" data-testing="challenge-dialog"/>
        </template>
        <add-edit-challenge-dialog v-model="showEditChallengeDialog" :challengeId="challenge.id" @confirm="onChallengeUpdated" @deleted="onChallengeUpdated" class="addEditChallengeDialog" />
        <submission-result-dialog v-model="showSubmissionResultDialog" :challenge="challenge" :submission-result="submissionResult" class="submissionResultDialog"/>
    </v-card>
</template>

<script setup lang="ts">
import { computed, ref } from 'vue';
import { IChallenge, ISubmissionResult, SubmissionResult } from '@cyber-range/cyber-range-api-ctf-challenge-client';
import { useThemeStore } from '@/stores/themeStore';
import { useApiClientStore } from '@stores/apiClientStore';
import { useAuthorizationStore } from '@/stores/authorizationStore';
import { useCompetitionStore } from '@/stores/competitionStore';
import { useChallengeStore } from '@/stores/challengeStore';
import { useSubmissionStore } from '@/stores/submissionStore';

const props = defineProps<{ challenge: IChallenge }>();

const showChallenge = ref(false);
const showEditChallengeDialog = ref(false);
const showSubmissionResultDialog = ref(false);
const submissionResult = ref<ISubmissionResult>(new SubmissionResult());

const isDark = computed(() => useThemeStore().isDark);
const isLoading = computed(() => useApiClientStore().isLoading);

const isSolved = computed(() => useSubmissionStore().isSolved[props.challenge.id]);
const isLocked = computed(() => useSubmissionStore().isLocked(props.challenge.id));
const canUpdateChallenge = computed(() => 
{
    const { id: competitionId, organizationId } = useCompetitionStore().currentCompetition;
    return useAuthorizationStore().canUpdateChallenge(competitionId, organizationId);
});

const hasRemainingAttempts = computed(() => 
{
    if (props.challenge.settings?.maxNumberOfAttempts == undefined) {
        return true;
    }

    const statistics = useSubmissionStore().myTeamsChallengesStatistics[props.challenge.id];
    if (!statistics) {
        return true;
    }

    return statistics.failedAttempts < props.challenge.settings.maxNumberOfAttempts;
});

const color = computed(() => 
{
    if (isSolved.value) {
        return 'solved';
    } else if (isLocked.value) {
        return '';
    } else if (!hasRemainingAttempts.value) {
        return 'noMoreAttempts';
    } else {
        return 'unsolved';
    }
});

const classes = computed(() => [`${color.value}Text--text`, color.value]);

const onClicked = () => 
{
    showChallenge.value = true;
};

const onEditChallenge = async () => 
{
    showEditChallengeDialog.value = true;
};

const onChallengeUpdated = async () => 
{
    await useChallengeStore().fetchChallenges();
};

const onSubmissionResult = (result: ISubmissionResult) => 
{
    submissionResult.value = result;
    showSubmissionResultDialog.value = true;
};
</script>
<style scoped>
header
{
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    display: block;
}

/* US Cyber Games Theme (.usCyberGamesSeasonII) */
.usCyberGamesSeasonII .solved.obviousWhenSelected:focus
{
    outline: 6px solid var(--v-unsolved-base);
}
.usCyberGamesSeasonII .unsolved.obviousWhenSelected:focus
{
    outline: 6px solid var(--v-solved-base);
}

/* Comic Book Theme (.comicbook) */
.comicbook .v-card
{
    font-family: 'ComicNeue-Bold';
    letter-spacing: normal;
    border-radius: 0%;
}
.comicbook .v-card:not(:focus)
{
    outline: 2px solid black;
}
.comicbook .v-card__text
{
    text-align: center !important;
}
</style>