<template>
    <div>
        <v-row row wrap class="ma-2 py-3" justify="space-between">
            <h2 class="px-3">{{t('CHALLENGES_TITLE')}}</h2>
            <div>
                <v-layout>
                    <add-challenge-menu v-if="canCreateChallenge(competition.id, competition.organizationId)" @added='onChallengesAdded' />
                    <v-flex ml-3>
                        <v-tooltip v-if="canImportChallenges()" bottom>
                            <template v-slot:activator="{ on }">
                                <v-btn @click="onImportClicked" v-on="on" class="importChallengesButton" small fab outlined :aria-label="t('CHALLENGE_IMPORT_TOOLTIP')">
                                    <v-icon>upload</v-icon>
                                </v-btn>
                            </template>
                            {{t('CHALLENGE_IMPORT_TOOLTIP')}}
                        </v-tooltip>
                    </v-flex>
                </v-layout>
            </div>
        </v-row>
        <v-data-table show-select v-model="selectedChallengeViews" item-key="challenge.id" :dark="isDark" :headers="headers" :items="items" :loading="isLoading" :loading-text="t('CHALLENGES_LOADING')" :no-data-text="t('CHALLENGES_NO_DATA')" class="elevation-0 alphaBackground" id="manageChallengesTable" @toggle-select-all="onToggleSelectAllChallenges" @item-selected="onChallengeSelected" @current-items="onTableItemsChanged">
            <template v-slot:top>
                <table-header deleteButton :exportButton="canExportChallenges()" :selected="selectedChallenges.length" :page="pageSize" :total="items.length" item="challenges" @select-all="onSelectAllChallenges" @clear="onClearSelectedChallenges" @delete="onDeleteSelectedChallenges" @export="onExportSelectedChallenges"/>
            </template>
            <template v-slot:[`header.action`]="{ header }">
                <td><span class="visually-hidden">{{ header.text }}</span></td>
            </template>
            <template v-slot:header.action>
                <v-tooltip bottom>
                    <template v-slot:activator="{ on }"> 
                        <v-icon v-on="on" @click="onExportToCsvClicked" :aria-label="t('CHALLENGE_HEADER_EXPORT_CSV')" :dark="isDark">save_alt</v-icon>
                    </template>
                    <span> {{t('CHALLENGE_HEADER_EXPORT_CSV')}} </span>
                </v-tooltip>
            </template>
            <template v-if="canEditChallenge || canDeleteChallenge" v-slot:item.action="{ item }">
                <accessible-menu offset-y :label="t('CHALLENGE_ACTIONS_LABEL', { name: item.name })">
                    <v-list>
                        <v-list-item v-if="canEditChallenge" @click="onEditChallengeClicked(item)">
                            <v-list-item-avatar>
                                <v-icon>edit</v-icon>
                            </v-list-item-avatar>
                            <v-list-item-title>{{t('CHALLENGE_EDIT')}}</v-list-item-title>
                        </v-list-item>
                        <v-list-item v-if="canDeleteChallenge" @click="onDeleteChallengeClicked(item)">
                            <v-list-item-avatar>
                                <v-icon>delete</v-icon>
                            </v-list-item-avatar>
                            <v-list-item-title>{{t('CHALLENGE_DELETE')}}</v-list-item-title>
                        </v-list-item>
                    </v-list>
                </accessible-menu>
            </template>
            <template v-slot:header.data-table-select="{ props, on }">
                <v-checkbox :aria-label="t('CHALLENGE_TOGGLE_SELECT_ALL_LABEL')" class="pa-0 ma-0"
                    hide-details @change="on.input" :input-value="props.value" 
                    :indeterminate="props.indeterminate" />
            </template>
            <template v-slot:item.data-table-select="{ item, isSelected, select }">
                <v-checkbox class="pa-0 ma-0" hide-details
                    :aria-label="t('CHALLENGE_SELECT_LABEL', {name: item.name})" 
                    @change="select" :input-value="isSelected" />
            </template>
        </v-data-table>
        <add-edit-challenge-dialog v-model="showEditChallengeDialog" :challengeId="selectedChallenge.id" @confirm="refresh" @deleted="refresh"/>
        <delete-challenge-dialog v-model="showDeleteChallengeDialog" :challenge="selectedChallenge" @confirm="refresh" />
        <delete-challenges-dialog v-model="showDeleteChallengesDialog" :challenges="selectedChallenges" @confirm="refresh" />
        <export-challenges-dialog v-model="showExportChallengesDialog" :challenges="selectedChallenges" @confirm="refresh"/>
        <import-challenges-dialog v-model="showImportDialog" @confirm="refresh"/>
    </div>
</template>

<script setup lang="ts">
import Vue, { computed, onBeforeMount, onMounted, ref } from 'vue';
import { Challenge, IChallenge } from '@cyber-range/cyber-range-api-ctf-challenge-client';
import StatisticsEntryView from './StatisticsEntryView';
import { ICompetition } from '@cyber-range/cyber-range-api-ctf-competition-client';
import TitleStrings from '@/entities/strings/definitions/titleStrings';
import { useThemeStore } from '@/stores/themeStore';
import { useApiClientStore } from '@stores/apiClientStore';
import { useCompetitionStore } from '@stores/competitionStore';
import { useAuthorizationStore } from '@stores/authorizationStore';
import { csvExport } from '@/utils/csvExport';
import { useChallengeStore } from '@/stores/challengeStore';
import { useI18n } from 'vue-i18n-composable';
import { usePageTitle } from '@/composables/usePageTitle';


const { t } = useI18n();

usePageTitle(TitleStrings.en.TITLE_MANAGE_CHALLENGES);

const isDark = computed<boolean>(() => useThemeStore().isDark);
const isLoading = computed<boolean>(() => useApiClientStore().isLoading);
const competition = computed<ICompetition>(() => useCompetitionStore().currentCompetition);

const canCreateChallenge = (competitionId: string, organizationId: string): boolean => 
{
    return useAuthorizationStore().canCreateChallenge(competitionId, organizationId);
};

const canExportChallenges = (): boolean => 
{
    return useAuthorizationStore().canExportChallenges();
};

const canImportChallenges = (): boolean => 
{
    return useAuthorizationStore().canImportChallenges();
};

const headers = ref([]);
const showDeleteChallengeDialog = ref<boolean>(false);
const showDeleteChallengesDialog = ref<boolean>(false);
const showEditChallengeDialog = ref<boolean>(false);
const selectedChallengeViews = ref<StatisticsEntryView[]>([]);
const selectedChallenge = ref<IChallenge>(new Challenge());
const visibleChallengeIds = ref<string[]>([]);
const showExportChallengesDialog = ref<boolean>(false);
const showImportDialog = ref<boolean>(false);

const selectedChallenges = computed<IChallenge[]>(() => 
{
    return selectedChallengeViews.value.map(challengeView => challengeView.challenge);
});

const canDeleteChallenge = computed<boolean>(() => 
{
    return useAuthorizationStore().canDeleteChallenge(competition.value.id, competition.value.organizationId);
});

const canEditChallenge = computed<boolean>(() => 
{
    return useAuthorizationStore().canUpdateChallenge(competition.value.id, competition.value.organizationId);
});

const items = computed<StatisticsEntryView[]>(() => 
{
    return useChallengeStore().getChallenges().map(c => new StatisticsEntryView(c));
});

const pageSize = computed<number>(() => 
{
    return visibleChallengeIds.value.length;
});

const refresh = async () => 
{
    onClearSelectedChallenges();
    await useChallengeStore().fetchChallenges();
};

const onChallengesAdded = () => 
{
    refresh();
};

const onEditChallengeClicked = (item: StatisticsEntryView) => 
{
    selectedChallenge.value = item.challenge;
    showEditChallengeDialog.value = true;
};

const onDeleteChallengeClicked = (item: StatisticsEntryView) => 
{
    selectedChallenge.value = item.challenge;
    showDeleteChallengeDialog.value = true;
};

const onTableItemsChanged = (items: StatisticsEntryView[]) => 
{
    visibleChallengeIds.value = items.map(({ challenge }) => challenge.id);
};

const onToggleSelectAllChallenges = ({ value }: { value: boolean, items: StatisticsEntryView[] }) => 
{
    // Clear all selected challenges if value if false
    if (!value) 
    {
        onClearSelectedChallenges();
    }
};

const onChallengeSelected = ({ value, item }: { value: boolean, item: StatisticsEntryView }) => 
{
    // If a challenge on the page is deselected when all challenges were selected, change selection to only be the challenges on this page minus the deselected challenge
    if (selectedChallengeViews.value.length === items.value.length && !value) 
    {
        selectedChallengeViews.value = selectedChallengeViews.value.filter(({ challenge }) => challenge.id !== item.challenge.id && visibleChallengeIds.value.includes(challenge.id));
    }
};

const onSelectAllChallenges = () => 
{
    selectedChallengeViews.value = [...items.value];
};

const onClearSelectedChallenges = () => 
{
    selectedChallengeViews.value = [];
};

const onExportSelectedChallenges = () => 
{
    showExportChallengesDialog.value = true;
};

const onImportClicked = async () => 
{
    showImportDialog.value = true;
};

const onExportToCsvClicked = async () => 
{
    const excludeHeaderValues = ['action'];
    const headersToInclude = headers.value.filter(header => !excludeHeaderValues.includes(header['value']));

    const headerRow = headersToInclude.map(header => header['text']);
    const dataRows = items.value.map(item => headersToInclude.map(header => item[header['value']]));

    await csvExport(headerRow, dataRows, `ctf_challenges.csv`);
};

const onDeleteSelectedChallenges = async () => 
{
    if (selectedChallengeViews.value.length === 1) 
    {
        selectedChallenge.value = selectedChallenges.value[0];
        showDeleteChallengeDialog.value = true;
    } 
    else if (selectedChallengeViews.value.length > 1) 
    {
        showDeleteChallengesDialog.value = true;
    }
};

onBeforeMount(async () => 
{
    headers.value = [
        { text: t('CHALLENGES_HEADER_NAME'), value: 'name' },
        { text: t('CHALLENGES_HEADER_CATEGORY'), value: 'category' },
        { text: t('CHALLENGES_HEADER_POINTS'), value: 'points', align: 'center' },
        { text: t('CHALLENGES_HEADER_SUCCESSFUL_ATTEMPTS'), value: 'successfulAttempts', align: 'center' },
        { text: t('CHALLENGES_HEADER_FAILED_ATTEMPTS'), value: 'failedAttempts', align: 'center' }
    ];

    if (useAuthorizationStore().canUpdateChallengeProtectedInformation()) 
    {
        headers.value.splice(1, 0, { text: t('CHALLENGES_HEADER_CREATION_DATE'), value: 'creationDate' });
    }

    if (canEditChallenge.value || canDeleteChallenge.value) 
    {
        headers.value.unshift({ text: 'Actions', value: 'action', align: 'center', sortable: false });
    }

    if (!useChallengeStore().isChallengesFetched) 
    {
        refresh();
    }
});
</script>

<style scoped>
.theme--dark .v-data-table
{
    
}
</style>
<style>
.v-data-table td
{
    color: var(--v-text-darken1) !important;
}
</style>