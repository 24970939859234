<template>
    <fieldset>
        <v-checkbox
            v-for="choice in challenge.choice.values"
            :key="choice.id"
            class="py-0 my-0"
            :value="choice.id"
            :label="choice.text"
            multiple
            color="blue"
            v-model="flags"
            :disabled="disabled"
            hide-details
            :rules="rules"
        />
        <v-input dense :value="flags" :rules="rules" />
    </fieldset>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import { IChallenge } from '@cyber-range/cyber-range-api-ctf-challenge-client';
import Rule from '@/validations/Rule';

const props = defineProps<{ 
    value: string | string[]; 
    challenge: IChallenge; 
    disabled?: boolean; 
}>();

const emit = defineEmits<{ (name: 'input', flag: string[]): void }>();

const rules = [Rule.require];

const flags = computed<string[]>({
    get() 
    {
        const value = props.value || [];
        return (typeof value === 'string') ? [value] : value;
    },
    set(flag: string[]) 
    {
        emit('input', flag);
    }
});
</script>

<style scoped>
fieldset {
    border: none;
}
</style>
