<template>
    <confirmation-dialog v-model="showDialog" :title="challenge.name" :confirm="challengeDialogConfirmText" @confirm="confirm" @cancel="cancel" :loading="isLoading" class="challengeDialog">
        <v-col cols="12" v-if="fetchingChallenge">
            <loading />
        </v-col>
        <v-col cols="12" class="pt-0 pb-0">
            <p class="challengeDialogDescription" v-html="challengeDescription" />
        </v-col>
        <v-col cols="12" v-if="challenge.artifacts" class="pt-0">
            <artifacts :artifacts="challenge.artifacts" :challenge-id="challenge.id" class="artifacts" />
            <hints :challenge="challenge" class="hints" />
        </v-col>
        <v-col cols="12" class="pt-0 pb-0">
            <v-form ref="form" :lazy-validation="true" @submit.prevent="confirm">
                <component :is="challengeFlagInputComponent" v-model="flag" :challenge="challenge" :disabled="isLoading" class="challengeDialogFlag pt-0" id="challengeDialogFlag" />
            </v-form>
            <span v-if="flagHint" class="grey--text text--darken-2" id="remainingNumberOfAttemptsText">{{ flagHint }}</span>
        </v-col>
        <div slot="titleActions" class="caption">{{t('CHALLENGE_DIALOG_POINTS', {points:challenge.points})}}</div>
        <template #bottomLeft>
            <v-tooltip v-if="canUpdateChallenge(competition.id, competition.organizationId)" top>
                <template v-slot:activator="{ on }">
                    <v-btn :aria-label="t('CHALLENGE_DIALOG_EDIT_THIS_CHALLENGE')" icon v-on="on" @click="onEditChallenge" :disabled="isLoading" :dark="isDark" :light="isLight" class="ml-3 editButton" id="editButton">
                        <v-icon>create</v-icon>
                    </v-btn>
                </template>
                <span>{{t('CHALLENGE_DIALOG_EDIT')}}</span>
            </v-tooltip>
            <accessible-menu v-if="canSaveChallenge(competition.id, competition.organizationId)" offset-y :label="t('CHALLENGE_DIALOG_SAVE')" class="saveMenu">
                <template #menuToggle="{ on: menuOn, value, listId }">
                    <v-tooltip top  data-testing="save-challenge-tooltip">
                        <template v-slot:activator="{ on: tooltipOn, attrs }">
                            <div v-bind="attrs" v-on="{...tooltipOn}">
                                <v-btn v-on="{...tooltipOn, ...menuOn}" :disabled="isLoading || cannotSaveEnvironmentArtifacts" icon plain small :aria-label="t('CHALLENGE_DIALOG_SAVE')" :aria-owns="listId" aria-haspopup="true" :aria-expanded="`${value}`" class="saveMenuToggle">
                                    <v-icon>save</v-icon>
                                </v-btn>
                            </div> 
                        </template>
                        <span>{{ saveTooltipText }}</span>
                    </v-tooltip>
                </template>
                <v-list>
                    <v-list-item @click="onPersonalSaveClicked" class="visibility-item">
                        <v-list-item-title>{{t('CHALLENGE_DIALOG_SAVE_VISIBILITY_PERSONAL')}}</v-list-item-title>
                    </v-list-item>
                    <v-list-item v-if="canSaveChallengeWithOrganizationalVisibility(competition.organizationId)" @click="onOrganizationalSaveClicked" class="visibility-item">
                        <v-list-item-title>{{t('CHALLENGE_DIALOG_SAVE_VISIBILITY_ORGANIZATIONAL')}}</v-list-item-title>
                    </v-list-item>
                    <v-list-item v-if="canSaveChallengeWithGlobalVisibility()" @click="onGlobalSaveClicked" class="visibility-item">
                        <v-list-item-title>{{t('CHALLENGE_DIALOG_SAVE_VISIBILITY_GLOBAL')}}</v-list-item-title>
                    </v-list-item>
                </v-list>
            </accessible-menu>

            <solved-by v-else :statistics="challenge.statistics" />
        </template>
        <template #bottom v-if="competitionStarted && canViewFlagsAndHints">
            <v-btn @click="onTestSubmissionClicked" elevation="0" id="challengeDialogTestButton">{{ t('CHALLENGE_DIALOG_TEST') }}</v-btn>
        </template>
        <team-required-dialog v-model="showTeamRequiredDialog" />
        <test-challenge-dialog v-if="canViewFlagsAndHints" v-model="showTestChallengeDialog" :challengeId="challengeId" :flag="flag" @confirm="onTestChallengeDialogConfirm"/>
        <save-challenge-dialog v-model="showSaveChallengeDialog" :challengeId="challengeId" :visibility="saveChallengeVisibility" @confirm="onSaveChallengeDialogConfirm" @cancel="onSaveChallengeDialogConfirm" />
    </confirmation-dialog>
</template>

<script setup lang="ts">
import { computed, onMounted, ref, watch } from 'vue';
import { IChallenge, ChallengeArtifactType } from '@cyber-range/cyber-range-api-ctf-challenge-client';
import moment from 'moment';
import { selectChallengeFlagInput } from './challengeFlagInput/selectChallengeFlagInput'
import { useThemeStore } from '@/stores/themeStore';
import { LibraryVisibility } from '@cyber-range/cyber-range-api-ctf-library-client';
import { useApiClientStore } from '@stores/apiClientStore';
import { useCompetitionStore } from '@stores/competitionStore';
import { useAuthorizationStore } from '@stores/authorizationStore';
import { useScoreStore } from '@stores/scoreStore';
import { useTeamStore } from '@/stores/teamStore';
import { useSubmissionStore } from '@/stores/submissionStore';
import { useI18n } from 'vue-i18n-composable';

const props = defineProps<{ 
    challengeId?: string;
    value: boolean;
}>();

const emit = defineEmits<{
    (name: 'confirm', state: boolean): void;
    (name: 'edit', challengeId: string): void;
    (name: 'result', result: any): void;
    (name: 'cancel', state: boolean): void;
    (name: 'input', state: boolean): void; 
}>();

const form = ref<HTMLElement | null>(null);
const challenge = ref<IChallenge>({} as IChallenge);
const showDialog = ref(false);
const showSaveChallengeDialog = ref(false);
const saveChallengeVisibility = ref(LibraryVisibility.Personal);
const showTeamRequiredDialog = ref(false);
const showTestChallengeDialog = ref(false);
const fetchingChallenge = ref(true);
const flag = ref<string | string[]>('');
const remainingNumberOfAttempts = ref(Number.MAX_SAFE_INTEGER);

const { t, tc } = useI18n();

const myTeamId = computed(() => useTeamStore().getMyTeam);
const isDark = computed(() => useThemeStore().isDialogDark);
const isLight = computed(() => useThemeStore().isDialogLight);
const isLoading = computed(() => useApiClientStore().isLoading);
const challengeApiClient = computed(() => useApiClientStore().challengeApiClient);
const competition = computed(() => useCompetitionStore().currentCompetition);

const canUpdateChallenge = (competitionId: string, organziationId: string): boolean => 
{
    return useAuthorizationStore().canUpdateChallenge(competitionId, organziationId);
};

const canSaveChallenge = (competitionId: string, organziationId: string): boolean => 
{
    return useAuthorizationStore().canSaveChallenge(competitionId, organziationId);
};

const canSaveChallengeWithGlobalVisibility = (): boolean => 
{
    return useAuthorizationStore().canSaveChallengeWithGlobalVisibility();
};

const canSaveChallengeWithOrganizationalVisibility = (organziationId: string): boolean => 
{
    return useAuthorizationStore().canSaveChallengeWithOrganizationalVisibility(organziationId);
};

const challengeDescription = computed(() => 
{
    return challenge.value?.description?.replace(/\n/g, '<br>');
});

const canViewFlagsAndHints = computed(() => 
{
    return useAuthorizationStore().canViewFlagsAndHints(competition.value.id, competition.value.organizationId);
});

const competitionStarted = computed(() => 
{
    return !(moment().isBefore(competition.value.settings.startTime));
});

const challengeDialogConfirmText = computed(() => 
{
    return competitionStarted.value ? t('CHALLENGE_DIALOG_CONFIRM') : t('CHALLENGE_DIALOG_TEST');
});

const flagHint = computed(() => 
{
    return remainingNumberOfAttempts.value < 10 ? tc('CHALLENGE_DIALOG_REMAINING_NUMBER_OF_ATTEMPTS', remainingNumberOfAttempts.value).toString() : '';
});

const cannotSaveEnvironmentArtifacts = computed(() => 
{
    return (challenge.value.artifacts?.some(a => a.type === ChallengeArtifactType.Environment) && !useAuthorizationStore().canCreateEnvironmentArtifact(challenge.value.competitionId, challenge.value.organizationId));
});

const saveTooltipText = computed(() => 
{
    return cannotSaveEnvironmentArtifacts.value 
        ? t('CHALLENGE_DIALOG_SAVE_DISABLED_UNAUTHORIZED_ENVIRONMENT_ARTIFACTS')
        : t('CHALLENGE_DIALOG_SAVE');
});

const challengeFlagInputComponent = computed(() => 
{
    return selectChallengeFlagInput(challenge.value);
});

watch(() => props.value, async (value) => 
{
    if (value) await load();
});

const updateRemainNumberOfAttempts = async (): Promise<void> => 
{
    if (myTeamId.value) 
    {
        let statistics = await useSubmissionStore().getTeamChallengeSubmissionStatistics({ teamId: myTeamId.value, challengeId: props.challengeId, background: true });

        if (challenge.value.settings?.maxNumberOfAttempts) 
        {
            remainingNumberOfAttempts.value = challenge.value.settings.maxNumberOfAttempts - (statistics.failedAttempts + statistics.successfulAttempts);
        }
    }
};

watch(challenge, async (value) => 
{
    if (value) 
    {
        await updateRemainNumberOfAttempts();
    }
}, { immediate: true });

const onEditChallenge = () => 
{
    emit('edit', props.challengeId);
    close();
};

onMounted(async () => 
{
    showDialog.value = props.value;

    if (showDialog.value) await load();
});

const load = async () => 
{
    try 
    {
        form.value?.['reset']?.();
        flag.value = '';
        fetchingChallenge.value = true;
        challenge.value = await challengeApiClient.value.getOne(props.challengeId);
        showDialog.value = true;
    } 
    finally 
    {
        fetchingChallenge.value = false;
    }
};



const confirm = async () => 
{
    if (!competitionStarted.value && canViewFlagsAndHints.value) 
    {
        showTestChallengeDialog.value = true;
        return;
    }

    if (!myTeamId.value) 
    {
        showTeamRequiredDialog.value = true;
        return;
    }

    if ((<any>form.value).validate() === false) return;

    let result = await challengeApiClient.value.putChallengeSubmission(props.challengeId, flag.value);

    emit('result', result);

    if (result.correct) 
    {
        useSubmissionStore().fetchSubmissions();
        useScoreStore().fetchTeamsScores();
        emit('confirm', true);
        close();
    } 
    else 
    {
        await updateRemainNumberOfAttempts();
    }
};

const cancel = async () => 
{
    emit('cancel', true);
    close();
};

const close = () => 
{
    showDialog.value = false;
    emit('input', false);
};

const onTestSubmissionClicked = () => 
{
    showTestChallengeDialog.value = true;
};

const onTestChallengeDialogConfirm = () => 
{
    showTestChallengeDialog.value = false;
};

const openSaveChallengeDialog = (visibility: LibraryVisibility) => 
{
    saveChallengeVisibility.value = visibility;
    showSaveChallengeDialog.value = true;
};

const onGlobalSaveClicked = () => 
{
    openSaveChallengeDialog(LibraryVisibility.Global);
};

const onOrganizationalSaveClicked = () => 
{
    openSaveChallengeDialog(LibraryVisibility.Organizational);
};

const onPersonalSaveClicked = () => 
{
    openSaveChallengeDialog(LibraryVisibility.Personal);
};

const onSaveChallengeDialogConfirm = () => 
{
    showSaveChallengeDialog.value = false;
};
</script>
</script>