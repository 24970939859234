<template>
    <confirmation-dialog v-model="showDialog" :title="t('CHALLENGE_SAVE_CHALLENGE_DIALOG_TITLE')" @confirm="confirm" @cancel="cancel" :loading="isLoading" class="saveChallengeDialog">
        {{t('CHALLENGE_SAVE_CHALLENGE_DIALOG_TEXT', {visibility: visibility})}}
        <v-checkbox v-if="showPersistProtectedInformationDateCheckbox" v-model="persistProtectedInformationDate" :label='t("CHALLENGE_SAVE_CHALLENGE_DIALOG_PERSIST_PROTECTED_INFORMATION_DATE_CHECKBOX_LABEL", {date:challengeCreationDate})' :dark="isDark" :light="isLight" color="unset" :disabled="isLoading" id="checkboxPersistProtectedInformationDate"/>
    </confirmation-dialog>
</template>

<script setup lang="ts">
import { computed, onMounted, ref, watch } from 'vue';
import { IChallenge, Challenge } from '@cyber-range/cyber-range-api-ctf-challenge-client';
import { LibraryVisibility, ProtectedInformation } from '@cyber-range/cyber-range-api-ctf-library-client';
import { useThemeStore } from '@/stores/themeStore';
import { useApiClientStore } from '@stores/apiClientStore';
import { useAuthorizationStore } from '@stores/authorizationStore';
import { useI18n } from 'vue-i18n-composable';

const props = defineProps<{
    value: boolean;
    challengeId: string;
    visibility?: LibraryVisibility;
}>();

const emit = defineEmits<{
    (name: 'confirm', state: boolean): void;
    (name: 'cancel', state: boolean): void;
    (name: 'input', value: boolean): void;
}>();

const challenge = ref<IChallenge>(Challenge.fromJson({}));
const challengeCreationDate = ref('');
const showDialog = ref(false);
const showPersistProtectedInformationDateCheckbox = ref(false);
const persistProtectedInformationDate = ref(true);

const { t } = useI18n();

const isDark = computed(() => useThemeStore().isDialogDark);
const isLight = computed(() => useThemeStore().isDialogLight);
const isLoading = computed(() => useApiClientStore().isLoading);
const challengeClient = computed(() => useApiClientStore().challengeApiClient);
const libraryApiClient = computed(() => useApiClientStore().libraryApiClient);

watch(() => props.value, () => 
{
    showDialog.value = props.value;
}, { immediate: true });

onMounted(async () => 
{
    showDialog.value = props.value;

    if (showDialog.value) await load();

    if (useAuthorizationStore().canUpdateChallengeProtectedInformation()) 
    {
        challenge.value = await challengeClient.value.getOne(props.challengeId);
        challengeCreationDate.value = challenge.value?.protectedInformation?.creationDate || ' ';
        showPersistProtectedInformationDateCheckbox.value = true;
    }
});

const load = async () => 
{
    showDialog.value = true;
};

const confirm = async () => 
{
    let libraryId = await challengeClient.value.save(props.challengeId, props.visibility);

    if (useAuthorizationStore().canUpdateChallengeProtectedInformation() && !persistProtectedInformationDate.value) 
    {
        let savedEntry = await libraryApiClient.value.getOne(libraryId);
        let protectedInformation = ProtectedInformation.fromJson({
            authorName: savedEntry?.protectedInformation?.authorName,
            authorEmail: savedEntry?.protectedInformation?.authorEmail,
            creationDate: new Date().toISOString().substring(0, 10)
        });
        await libraryApiClient.value.update(libraryId, { protectedInformation });
    }
    emit('confirm', true);
    close();
};

const cancel = () => 
{
    emit('cancel', true);
    close();
};

const close = () => 
{
    showDialog.value = false;
    emit('input', false);
};
</script>